<template>
    <app-autocomplete
        v-model="valueLocal"
        :schema="getAutocompleteSchema"
        :rules="field.rules"
        :label="field.titleSingular"
        :add-form="field.form"
        :filter="filter"
        :custom-filter="customFilter"
        :is-readonly="isReadonly"
        :is-disabled="isDisabled"
        :claim-name="field.claim" />
</template>

<script>
export default {
    components: {
        appAutocomplete: () => import("./AppAutocomplete.vue"),
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: null,
        },
        customFilter: {
            type: Object,
            default: null,
        },
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        getAutocompleteSchema() {
            return this.getSchema(this.field.foreignKeyPascalSingular);
        },
    },
};
</script>
